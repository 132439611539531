<template lang="pug">
  .page.page-home(class="flex flex-col min-h-screen items-center")
    .logo-container(class="container mx-auto flex justify-center")
      img.logo-img(src="@/assets/logo.png" alt="Coldfoot Hotshot")
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
};
</script>
